<template>
  <div>
    <MainLayout>
      <template>
        <div class="keep__title">
          <p class="keep__text">Обслуживающая организация</p>
        </div>
      </template>
      <BasicTableGeneralInfo
        :table-data="getDataServiceOrganizationsRequisites"
        v-if="sourceObject.results"
      >
      </BasicTableGeneralInfo>

      <template>
        <div class="keep__title">
          <p class="keep__text">Детский сад</p>
        </div>
      </template>
      <BasicTableGeneralInfo
        :table-data="getDataRequisites"
        v-if="sourceObject.results"
      >
      </BasicTableGeneralInfo>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import BasicTableGeneralInfo from "@/components/tables/BasicTableGeneralInfo.vue";
import phoneFilter from "@/helpers/filters/phoneFilter";

export default {
  name: "GeneralInformation",
  components: { BasicTableGeneralInfo, MainLayout },
  computed: {
    sourceObject() {
      return this.$store.getters.getDealerInfo;
    },
    getDataServiceOrganizationsRequisites() {
      return [
        {
          text: "Название",
          value:
            this.sourceObject.results.service_organizations?.reduce(
              (firstValue, secondValue) => {
                firstValue += secondValue.name;
                return firstValue;
              },
              ""
            ) || ""
        },
        {
          text: "Адрес",
          value: this.sourceObject.results.object?.service_organization?.address
        },
        {
          text: "Телефон",
          value: `${phoneFilter.execute(
            this?.sourceObject?.results.object?.service_organization
              ?.phone_number
          )}`
        },
        {
          text: "E-mail",
          value: this.sourceObject?.results.object?.service_organization?.email
        },
        {
          text: "Техник/телефон ",
          value: (() => {
            let service = (this.sourceObject.results?.service_specialists ||
              [])[0];
            return service?.full_name
              ? service?.full_name
              : "-" + "/" + phoneFilter.execute(service?.phone_number || "-");
          })()
        }
      ];
    },
    getDataRequisites() {
      return [
        {
          text: "Руководитель",
          value: this.sourceObject.results
            ? this.sourceObject.results.director
            : ""
        },

        {
          text: "Адрес",
          value: `${this.sourceObject.results?.city_details?.name ||
            "-"}, ${this.sourceObject.results?.address || "-"}`
        },
        {
          text: "Телефон",
          value: phoneFilter.execute(this?.sourceObject?.results?.phone_number)
        },
        {
          text: "E-mail",
          value: this.sourceObject.results.email
        }
      ];
    },
    urlQuery() {
      return {
        // id: this.$store.getters.getCurrentOrganization,
        id: this.$store.getters.getCurrentOrganization,
        query: this.$route.query
      };
    }
  },
  created() {
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: this.urlQuery.query
    });

    this.isDateLoaded = true;
    this.$store.dispatch("fetchKinderInfo").finally(() => {
      this.isDateLoaded = false;
    });

    // this.$store.dispatch("fetchGeneralInformationInfo").finally(() => {
    //   this.isDateLoaded = false;
    // });
  },
  mounted() {
    this.$store.dispatch("fetchKinderInfo");
  }
};
</script>

<style scoped></style>
